<template>
  <div class="pa-4">
    <v-card class="mt-2">
      <v-card-title>
        <v-row class="mx-3">
          <v-col class="v-toolbar__title">Input Hasil Produksi</v-col>
          <v-spacer></v-spacer>
          <v-col md2 class="text-right">
            <v-btn color="" depressed small @click="$router.go(-1)"><v-icon small>mdi-arrow-left</v-icon> Back</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-3">
        <v-card elevation="0">
          <v-card elevation="0">
            <v-card-subtitle>
              <v-row>
                <v-col cols="3">
                  Nomor Produksi: <b>{{prodObj.prod_no}}</b>
                </v-col>
                <v-col>
                  Tanggal Produksi: <b>{{prodObj.production_date | moment}}</b>
                </v-col>
              </v-row>
              <div>
              </div>
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <v-card outlined>
                  <v-form v-model="valid" lazy-validation>
                  <v-simple-table fixed-header height="350px" id="variantTbl">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Volume</th>
                          <th class="text-left">Nama Variant</th>
                          <th class="text-left">Jumlah Request</th>
                          <th class="text-left">Jumlah Produk Jadi</th>
                          <th class="text-left">Jumlah Produk Ekstra</th>
                          <th class="text-left">Jumlah Produk Gagal</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(variant, idx) in prodVariants" :key="idx">
                          <td colspan="2" v-if="variant.add">
                            <v-layout>
                            <v-autocomplete :items="variants" item-text="name" item-value="code" return-object outlined dense 
                            hide-details class="compact-form" v-model="variant.variant_code" 
                            @change="setAddedVariant($event, variant, idx)" style="width:170px">
                              <template slot="selection" slot-scope="data">
                                {{ data.item.name }} - {{ data.item.size }} ml
                              </template>                            
                              <template slot="item" slot-scope="data">
                                {{ data.item.name }} - {{ data.item.size }} ml
                              </template>                            
                            </v-autocomplete>
                            </v-layout>
                          </td>
                          <td v-if="!variant.add" class="text-left">{{variant.size}} ml</td>
                          <td v-if="!variant.add" class="text-left">{{variant.name}}</td>
                          <td class="text-left">{{variant.qty_request}} botol</td>
                          <td class="text-left">
                              <v-text-field v-if="profile.role_code!='SUP'" type="number" class="compact-form" v-model="variant.qty_produced_temp" outlined dense hide-details="auto" suffix="botol" style="width:130px" :rules="[v => v >= Number(variant.qty_request) || 'Tidak boleh kurang dari Jml Request']"></v-text-field>
                              <v-text-field v-else type="number" class="compact-form" v-model="variant.qty_produced_temp" outlined dense hide-details="auto" suffix="botol" style="width:130px"></v-text-field>
                          </td>
                          <td class="text-left">
                              {{(variant.qty_produced_temp)?Number(variant.qty_produced_temp)-Number(variant.qty_request):0}} botol
                          </td>
                          <td class="text-left">
                              <v-text-field :disabled="profile.role_code!='SUP'" type="number" class="compact-form" v-model="variant.qty_failed" outlined dense hide-details="auto" suffix="botol" style="width:130px" min="0"></v-text-field>
                          </td>
                          <td>
                            <v-icon v-if="variant.add" color="red" @click="removeVariant(idx)">mdi-minus-circle-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-form>
                  </v-card>
                </v-col>
                <v-col align-self="end" class="mb-6">
                  <v-btn small color="green" class="white--text" @click="addVariant">
                    <v-icon small>mdi-plus</v-icon> Add
                    </v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
        <v-card elevation="0">
          <v-card-actions>
          <v-spacer></v-spacer>
          <span>Total Extra 45 ml = {{totalExtra(45)}} botol</span>
          <v-spacer></v-spacer>
          <span>Total Extra 250 ml = {{totalExtra(250)}} botol</span>
          <v-spacer></v-spacer>
          <span>Total Extra 350 ml = {{totalExtra(350)}} botol</span>
          <v-spacer></v-spacer>
          <span>Total Extra 490 ml = {{totalExtra(490)}} botol</span>
          <v-spacer></v-spacer>
          <span>Total Extra 1000 ml = {{totalExtra(1000)}} botol</span>
          <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

      </v-card-text>
      <v-card-actions class="pr-5 pb-3">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" style="text-transform:none" @click="save" :disabled="!valid">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    <v-dialog v-model="dialogWarning" max-width="500px">
      <v-card>
          <v-card-title class="body-1 text-center">Produk yang Anda pilih sudah ada.</v-card-title>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogWarning = false">OK</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="onProgress" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>        

  </div>
</template>

<script>
import moment from 'moment'
export default {
    data: () => ({
      dialog: false,
      prodVariants: null,
      coldPressed: false,
      tab: null,
      bottleSize: [],
      selectedSize: null,
      saved: false,
      saveError: false,
      rawmaterials: [],
      defects: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      dialogWarning: false,
      onProgress: false,
      qtyMsg: "",
      rules: {
        max(maxNum) {
          return v => (v || '').length <= maxNum || 'Max exceeded';
        },
      }
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodObj() {
        return this.$store.state.production.obj
      },
      variants() {
        return this.$store.state.variant.objs
      },
      profile () {
        return this.$store.state.profile
      },
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
    },

    async mounted() {
      this.initialize()
      this.$store.dispatch('variant/getObjs')
      const docs = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
      this.rawmaterials = docs.filter(el => {return el.category_code=='LNB'})
      console.log(this.rawmaterials)
    },

    methods: {
      checkQty(event) {
        console.log(event)
        // console.log(item)
        // if (Number(item.qty_produced_temp) < Number(item.qty_request)) {
        //   // Show warning and trim the input value
        //   this.qtyMsg = "Tidak boleh kurang dari Jumlah Requst";

        //   // Put the cursor back at the end of the input
        //   this.$nextTick(() => {
        //     this.$refs.inputField.$el.querySelector("input").focus();
        //   });
        // } else {
        //   this.showWarning = false;
        // }
      },

      totalExtra(vol) {
        let total = 0
        this.prodVariants.map(x => {
          if (vol==x.size)
            total += (Number(x.qty_produced_temp)-Number(x.qty_request))
        })
        return total
      },

      async initialize() {
        this.onProgress = true
        this.prodVariants = (await this.$store.dispatch('getObjs', {apidomain:'productionVariants?fields[prod_no]=', parameter:this.prodObj.prod_no})).data.data
        if (this.prodVariants.length > 0) {
          this.prodVariants.forEach(el => {
            this.$set(el, 'code', el.variant_code)
            this.$set(el, 'name', el.variant_name)
            this.$set(el, 'size', el.variant_size)
            this.$set(el, 'qty_request', el.qty_order)
            this.$set(el, 'qty_failed', el.qty_failed)
          })
        }
        else {
          this.prodVariants = (await this.$store.dispatch('getObjs', {apidomain:'others/orderproductionsum/', parameter:this.prodObj.prod_no})).data
          this.prodVariants.forEach(el => {
            this.$set(el, 'qty_produced_temp', el.qty_request)
            this.$set(el, 'qty_failed', 0)
          })
        }
        this.prodVariants = [...this.prodVariants].sort((a, b) => {
          if (a.variant_size !== b.variant_size) {
            return a.variant_size - b.variant_size;
          }
          // return a.variant_name.localeCompare(b.variant_name);
        });

        this.bottleSize = [...new Set(this.prodVariants.map(item => item.size))];
        this.selectedSize = this.bottleSize[0]
        this.onProgress = false
      },

      async save() {
        // this.onProgress = true
        let payload = []
        let completed = true
        console.log(this.prodVariants)

        this.prodVariants.forEach(obj => {
          if (!obj.qty_produced_temp) {
            completed = false
          }
          payload.push({
            prod_no: this.prodObj.prod_no,
            variant_code: obj.code,
            qty_order: obj.qty_request,
            qty_produced: obj.qty_produced,
            qty_produced_temp: obj.qty_produced_temp,
            qty_failed: obj.qty_failed
          })
        })

        let filling_end = ''
        if (completed) {
          filling_end = Date.now()
        }
        else {
          filling_end = ''
        }

        let newStatus = this.prodObj.status || ''
        if (this.prodObj.status == 'FINISHPROD'){
          newStatus = 'PROSES'
        }

        const prod = {
          prod_no: this.prodObj.prod_no,
          status: newStatus,
          production_date: this.prodObj.production_date,
          filling_end: filling_end
        }
        try {
          const prodDocs = await this.$store.dispatch('production/putObj', prod)
          console.log(prodDocs)
        }
        catch (err) {
          console.log(err)
          prod.details = []
          const prodDocs = await this.$store.dispatch('production/postObj', prod)
          console.log(prodDocs)
        }

        payload = {variants:payload}
        const docs = await this.$store.dispatch('production/postProductionVariants', payload)
        this.onProgress = false
        if (docs.status==200 || docs.status==201) {

          this.saved = true
          this.$router.go(-1)
        }
      },
      clean(obj) {
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
        return obj
      },
      removeVariant(idx) {
        this.prodVariants.splice(idx, 1)
        console.log(this.prodVariants, idx)
      },
      addVariant() {
        this.prodVariants.push({
          add: true,
          qty_request:0,
          qty_produced_temp:'',
        })
        let objDiv = document.getElementById("variantTbl").querySelector('.v-data-table__wrapper');
        setTimeout(()=> {
          objDiv.scrollTop = objDiv.scrollHeight
        }, 300)
      },
      setAddedVariant(selected, item, idx) {
        console.log(this.prodVariants)
        const found = this.prodVariants.find(element => {
          return element.code == selected.code
        });
        if (found) {
          this.removeVariant(idx)
          this.dialogWarning = true
          return 
        }
        item.code = selected.code
        item.size = selected.size
      },


    }    
}
</script>

<style>
  .v-label {
    font-size: 0.9rem;
  }
</style>